import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, accessToken: null, refreshToken: null },
    reducers: {
        setCredentials: (state, action) => {
            const { user, accessToken, refreshToken } = action.payload;
            state.user = user;
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;

            localStorage.setItem('dms-access-token', accessToken);
            localStorage.setItem('dms-refresh-token', refreshToken);
            localStorage.setItem('dms-user', JSON.stringify(user));
        },
        logOut: (state) => {
            state.user = null;
            state.accessToken = null;
            state.refreshToken = null;

            localStorage.removeItem('dms-user');
            localStorage.removeItem('dms-access-token');
            localStorage.removeItem('dms-refresh-token');
        },
    },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: any) => state.auth.user;
export const selectCurrentToken = (state: any) => state.auth.accessToken;
