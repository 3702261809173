import { TPrimarySaleOrder } from '@helpers/validation-schemas/primary-sale-order/sale-order.schema';
import { useGetPrimarySaleOrderQuery } from '@states/primary-sale-order/primary-sale-order.api';
import { TGetOneSuccessResponse } from '@type-defs/general/TGetOneSuccessResponse';

const useGetOnePrimarySaleorder = (id: string | number) => {
    const {
        data: rawData,
        error,
        isLoading,
        refetch,
    } = useGetPrimarySaleOrderQuery({
        primary_sales_order_id: id!,
    });

    const primarySaleOrderResponse =
        rawData as any as TGetOneSuccessResponse<TPrimarySaleOrder>;

    return {
        primarySaleOrderData: primarySaleOrderResponse?.data,
        primarySaleOrderMessage: primarySaleOrderResponse?.message,
        primarySaleOrderSuccess: primarySaleOrderResponse?.success,
        primarySaleOrderError: error,
        primarySaleOrderLoading: isLoading,
        primarySaleOrderRefetch: refetch,
    };
};

export default useGetOnePrimarySaleorder;
