export const WAREHOUSE = {
    BASE_PATH: '/warehouse-and-inventory/warehouse/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Warehouse',
    LIST_PAGE_TITLE: 'Warehouse List',
    EDIT_PAGE_TITLE: 'Edit Warehouse',
    GET_ALL: '/warehouse',
    CREATE: '/warehouse/create',
    UPDATE: '/warehouse/update/',
    DELETE: '/warehouse/delete/',
};
