import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { STOCK_BALANCE } from '@utils/constants/warehouse-and-inventory/stock-balance.constants';

export const stockBalanceApi = createApi({
    reducerPath: 'stockBalanceApi',
    tagTypes: ['StockBalance'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllStockBalances: builder.query<
            [],
            { page?: number; limit?: number }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: STOCK_BALANCE.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
    }),
});

export const { useGetAllStockBalancesQuery } = stockBalanceApi;
