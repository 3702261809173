import { useEffect } from 'react';
import { Toast } from '../helpers/popups/Toast';
import { TFailResponse } from '../types/general/TFailResponse';

export const useErrorHandling = (...errors: TFailResponse[]) => {
    useEffect(() => {
        errors.forEach((error) => {
            if (error) {
                let title = error.message || 'Something went wrong.';

                const { data } = error;

                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach((em: string) => {
                            Toast.fire({
                                title: em,
                                icon: 'error',
                            });
                        });
                    } else if (data.error) {
                        title = data.error;
                    } else if (data.message) {
                        title = data.message;
                    }
                }

                Toast.fire({
                    title,
                    icon: 'error',
                });
            }
        });
    }, [JSON.stringify(errors)]);
};
