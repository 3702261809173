export const SECONDARY_INVOICE = {
    BASE_PATH: '/secondary-sale-order/invoice/',
    MAKE_PAYMENT_RECEIVE_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/make-payment-receive';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Secondary Sales Order Invoice',
    LIST_PAGE_TITLE: 'Secondary Sales Order Invoice List',
    EDIT_PAGE_TITLE: 'Edit Secondary Sale Order Invoice',
    GET_ALL: 'secondary-sale-order/invoice',
    GET_ONE: 'secondary-sale-order/invoice/edit/',
    CREATE: 'secondary-sale-order/invoice',
    UPDATE: 'secondary-sale-order/invoice/update/',
    DELETE: 'secondary-sale-order/invoice/delete/',
    ADD_NEW_TITLE: 'Add New Secondary Sales Order Invoice',
};
